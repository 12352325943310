<template>
  <v-apex-chart
    type="pie"
    :series="chart.series"
    :options="chart.options"
    min-height="300"
    label="Open Vs. Closed Legal Matters"
    :loading="loading"
    @update="getStat(true)"
    :time="time"
  ></v-apex-chart>
</template>

<script>
import Chart from "@/mixins/Chart";
export default {
  mixins: [Chart],
  name: "closed_to_opened_pie_chart",
  data: () => ({
    config: {
      storageGet: "getAssStatData",
      axiosLink: "getAssStat",
      storageSet: "setAssStatData"
    }
  }),
  methods: {
    reformatWithGroupStat(stats, groupss, arr) {
      try {
        if (!arr || arr.length === 0) return [];
        let groups = this.distinctStatusWithGroups(stats, groupss);
        const isChild = el =>
          groups.some(
            r =>
              r.children &&
              r.children.length > 0 &&
              r.children.some(c => c.name === el.Status)
          );
        const isParent = el => groups.some(r => r.name === el.Status);
        const parent = el =>
          groups.filter(
            r =>
              r.children &&
              r.children.length > 0 &&
              r.children.some(c => c.name === el.Status)
          )[0];
        let response = [];
        arr.forEach(el => {
          if (isChild(el)) {
            if (response.some(x => x.id === parent(el).name)) {
              response[
                response.findIndex(x => x.id === parent(el).name)
              ].count += el.count;
            } else {
              response.push({ count: el.count, id: parent(el).name });
            }
          } else if (isParent(el)) {
            response.push({ count: el.count, id: el.Status });
          }
        });
        return response;
      } catch (e) {
        // console.log(e);
        return [];
      }
    },
    distinctStatusWithGroups(stats, groups) {
      let arr = [];
      stats.forEach(stat => {
        if (stat.groupId || stat.groupId === 0) {
          let group = groups[stat.groupId];
          if (arr.some(r => r.name === group.name)) {
            arr[arr.findIndex(r => r.name === group.name)].children.push(stat);
          } else {
            arr.push({ name: group.name, children: [stat] });
          }
        } else {
          arr.push(stat);
        }
      });
      return arr;
    }
  },
  computed: {
    chart() {
      try {
        let arr = this.stat_data;
        if (arr && arr.length > 0) {
          let options = {},
            openedSum = 0,
            series = [],
            labels = [];
          arr.forEach(status => {
            if (status.Status === "Closed") {
              labels.push(status.Status);
              series.push(status.count);
              return true;
            }
            openedSum = openedSum + status.count;
          });
          series.push(openedSum);
          labels.push("Open");
          options = {
            chart: {
              type: "pie"
            },
            dataLabels: {
              formatter(val, opts) {
                const name = opts.w.globals.labels[opts.seriesIndex];
                return [name, val.toFixed(1) + "%"];
              }
            },
            markers: {
              size: 0,
              style: "hollow"
            },
            responsive: [
              {
                breakpoint: 480,
                options: {
                  legend: {
                    show: false
                  }
                }
              }
            ],
            labels
          };
          return { options, series };
        }
        return {};
      } catch (e) {
        return {};
      }
    }
  }
};
</script>

<style scoped></style>
