<template>
  <v-container fill-height fluid>
    <v-row dense>
      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
        <open_cases_pie_chart />
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
        <closed_to_opened_pie_chart />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
        <last_three_years_payment_bar_chart />
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
        <payments_stat dense centered></payments_stat>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import open_cases_pie_chart from "@/components/dashboard/open_cases_pie_chart";
import closed_to_opened_pie_chart from "@/components/dashboard/closed_to_opened_pie_chart";
import last_three_years_payment_bar_chart from "@/components/dashboard/last_three_years_payment_bar_chart";
import payments_stat from "@/components/dashboard/Stats/payments_stat";
export default {
  name: "home",
  components: {
    payments_stat,
    last_three_years_payment_bar_chart,
    closed_to_opened_pie_chart,
    open_cases_pie_chart
  }
};
</script>
