<template>
  <v-apex-chart type="pie" :series="chart.series" :options="chart.options" min-height="300"
    label="Active Legal Matters By Status" :loading="loading" @update="getStat(true)" :time="time"
    error="No Open Matters in that association"></v-apex-chart>
</template>

<script>
import Chart from "@/mixins/Chart";
export default {
  name: "open_cases_pie_chart",
  mixins: [Chart],
  data: () => ({
    config: {
      storageGet: "getAssStatData",
      axiosLink: "getAssStat",
      storageSet: "setAssStatData"
    },
    stats: require("@/assets/status.json"),
    groups: require("@/assets/groups.json")
  }),
  methods: {
    reformatWithGroupStat(stats, groupss, arr) {
      try {
        if (!arr || arr.length === 0) return [];
        let groups = this.distinctStatusWithGroups(stats, groupss);
        const isChild = el =>
          groups.some(
            r =>
              r.children &&
              r.children.length > 0 &&
              r.children.some(c => c.name === el.Status)
          );
        const isParent = el => groups.some(r => r.name === el.Status);
        const parent = el =>
          groups.filter(
            r =>
              r.children &&
              r.children.length > 0 &&
              r.children.some(c => c.name === el.Status)
          )[0];
        let response = [];
        arr.forEach(el => {
          if (isChild(el)) {
            if (response.some(x => x.id === parent(el).name)) {
              response[
                response.findIndex(x => x.id === parent(el).name)
              ].count += el.count;
            } else {
              response.push({ count: el.count, id: parent(el).name });
            }
          } else if (isParent(el)) {
            response.push({ count: el.count, id: el.Status });
          }
        });
        return response;
      } catch (e) {
        console.log(e);
        return [];
      }
    },
    distinctStatusWithGroups(stats, groups) {
      try {

        let arr = [];
        stats.forEach(stat => {
          try {
            if (stat.groupId || stat.groupId === 0) {
              let group = groups[stat.groupId];
              if (arr.some(r => r.name === group.name)) {
                arr[arr.findIndex(r => r.name === group.name)].children.push(stat);
              } else {
                arr.push({ name: group.name, children: [stat] });
              }
            } else {
              arr.push(stat);
            }
          } catch (error) {
            console.log(error)
          }
        });
        return arr;
      } catch (error) {
        console.log(error)
      }

    }
  },
  computed: {
    chart() {
      try {
        let arr = this.reformatWithGroupStat(
          this.stats,
          this.groups,
          this.stat_data
        );
        if (!arr || arr.length === 0) throw new Error();
        let options = {},
          series = [],
          labels = [];
        arr.forEach(status => {
          labels.push(status.id);
          series.push(status.count);
        });
        options = {
          colors: [
            "#f44336",
            "#e91e63",
            "#9C27B0",
            "#9a5fc7",
            "#2196F3",
            "#03A9F4",
            "#FFEB3B",
            "#2ecc71",
            "#1abc9c",
            "#e91e63",
            "#e74c3c",
            "#e67e22",
            "#f1c40f"
          ],
          //     [
          //   "#9b59b6",
          //   "#3498db",
          //   "#2ecc71",
          //   "#1abc9c",
          //   "#f1c40f",
          //   "#e74c3c",
          //   "#74b9ff",
          //   "#fd79a8",
          //   "#ff7675",
          //   "#45aaf2",
          //   "#778ca3",
          //   "#fed330",
          //   "#fc5c65",
          //   "#2bcbba",
          //   "#a55eea",
          //   "#ff5252",
          //   "#ffb142",
          //   "#34ace0"
          // ]
          chart: {
            type: "pie"
          },
          dataLabels: {
            style: {
              fontSize: "13px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: "900",
              colors: ["#34495e"]
            },
            dropShadow: {
              enabled: true,
              top: 0,
              left: 0,
              blur: 0.5,
              color: "#fff",
              opacity: 0.45
            },
            formatter(val, opts) {
              const name = opts.w.globals.labels[opts.seriesIndex];
              return [name, val.toFixed(1) + "%"];
            }
          },
          markers: {
            size: 0,
            style: "hollow"
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                legend: {
                  show: false
                }
              }
            }
          ],
          labels
        };
        return { options, series };
      } catch (e) {
        return {};
      }
    }
  }
};
</script>

<style scoped></style>
