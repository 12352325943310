import { render, staticRenderFns } from "./closed_to_opened_pie_chart.vue?vue&type=template&id=251d47f1&scoped=true&"
import script from "./closed_to_opened_pie_chart.vue?vue&type=script&lang=js&"
export * from "./closed_to_opened_pie_chart.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "251d47f1",
  null
  
)

export default component.exports