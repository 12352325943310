<template>
  <v-apex-chart
    type="bar"
    :series="chart.series"
    :options="chart.options"
    min-height="300"
    label="Total Payments in last 3 years"
    :loading="loading"
    @update="getStat(true)"
    :time="time"
  ></v-apex-chart>
</template>

<script>
import { doubleRound, moneyFormat } from "@/functions";
import Chart from "@/mixins/Chart";
export default {
  name: "last_three_years_payment_bar_chart",
  mixins: [Chart],
  data: () => ({
    config: {
      storageGet: "getPayTenData",
      axiosLink: "getPayTen",
      storageSet: "setPayTenData"
    }
  }),
  computed: {
    chart() {
      if (Object.keys(this.stat_data || {}).length > 0) {
        let options = {},
          series = [],
          categories = [],
          data = [];
        let years = [-2, -1, 0];
        years.forEach(yearMod => {
          let date = new Date(
            new Date(
              new Date(
                new Date().getFullYear() + yearMod,
                new Date().getMonth()
              )
            )
          ).getFullYear();
          categories.push(date.toString());
          if (typeof this.stat_data[date] !== typeof undefined) {
            data.push(Math.round(this.stat_data[date]));
          } else {
            data.push(0);
          }
        });
        options = {
          chart: {
            type: "bar"
          },
          plotOptions: {
            bar: {
              dataLabels: {
                position: "bottom"
              },
              columnWidth: Math.round((categories.length / 20) * 100) + "%"
            }
          },
          dataLabels: {
            enabled: true,
            offsetY: 10,
            style: {
              fontSize: "12px",
              colors: [this.$vuetify.theme.currentTheme.primary]
            },
            formatter: val => {
              return "$" + doubleRound(val);
            }
          },
          xaxis: {
            categories,
            position: "bottom",
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            },
            crosshairs: {
              fill: {
                type: "gradient",
                gradient: {
                  colorFrom: "#D8E3F0",
                  colorTo: "#BED1E6",
                  stops: [0, 100],
                  opacityFrom: 0.4,
                  opacityTo: 0.5
                }
              }
            },
            labels: {
              // formatter: (val)=> {
              //   return "$ " + this.moneyFormat(val);
              // }
            },
            tooltip: {
              enabled: true
            }
          },
          yaxis: {
            axisBorder: {
              show: true
            },
            axisTicks: {
              show: true
            },
            labels: {
              formatter: val => {
                return moneyFormat(val);
              }
            }
          }
        };
        series = [
          {
            name: "Payment",
            data
          }
        ];
        return { options, series };
      }
      return {};
    }
  }
};
</script>

<style scoped></style>
